import React from 'react'

function Photos() {
    
    return (
        <div>
            <h2>Photos</h2>
            
            <img alt="Cutie" src={require('../../assets/erkmenesen2.jpg')} />
            <img alt="Coolboi" src={require('../../assets/erkmenesen4.jpg')} />
        </div>
    )
}

export default Photos